import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function WhatsAppFloat() {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const YOUR_PHONE_NUMBER = "+5522999437340";

  const handleSend = () => {
    const whatsappMessage = `Nome: ${name}%0ASolicitação: ${message}`;
    const whatsappUrl = `https://wa.me/${YOUR_PHONE_NUMBER}?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, '_blank');
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg"
        onClick={toggleModal}
      >
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </button>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg relative">
            <button className="absolute top-2 right-2 text-gray-600" onClick={toggleModal}>
              &times;
            </button>
            <h2 className="text-lg font-bold mb-2">Suporte via WhatsApp</h2>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Seu Nome"
              required
            />
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Sua Mensagem"
              required
            ></textarea>
            <button
              className="bg-green-500 text-white px-4 py-4 rounded"
              onClick={handleSend}
            >
              Enviar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default WhatsAppFloat;
