import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import WhatsAppFloat from './WhatsAppFloat';

function Editar() {
  const { type } = useParams();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post('https://file.io', formData);
        setImageUrl(response.data.link);
        setPreview(URL.createObjectURL(file));
      } catch (error) {
        console.error('Erro ao fazer upload da imagem:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const YOUR_PHONE_NUMBER = "+5522999437340";

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappMessage = `Voce tem um novo servico !! Nome: ${name} Email: ${email} Servico: ${description} Imagem: ${imageUrl}`;
    const whatsappUrl = `https://wa.me/${YOUR_PHONE_NUMBER}?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="max-w-md rounded overflow-hidden shadow-lg bg-white p-6">
        <h2 className="text-xl font-bold mb-4">Editar: {type.replace('-', ' ')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Nome
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nome"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
              Descrição do Serviço
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Descrição"
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="image">
              Upload de Imagem
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="image"
              type="file"
              onChange={handleImageUpload}
            />
          </div>
          {preview && (
            <div className="mb-4">
              <img src={preview} alt="Preview" className="max-w-full h-auto rounded" />
            </div>
          )}
          <button
            className="bg-black text-white px-4 py-2 rounded"
            type="submit"
            disabled={loading}
          >
            {loading ? 'Enviando...' : 'Salvar e Enviar para WhatsApp'}
          </button>
        </form>
      </div>
      <WhatsAppFloat />
    </div>
  );
}

export default Editar;
