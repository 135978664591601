import React from 'react';

// Definindo o enum para os tipos de edição
const EditTypes = {
  MELHORAR_IMAGEM: 'melhorar-imagem',
  RESTAURAR_IMAGEM: 'restaurar-imagem',
  RETIRAR_ADICIONAR_OBJETOS: 'retirar-adicionar-objetos',
  EDITAR_MEMBROS: 'editar-membros',
  EDICAO_PERSONALIZADA: 'edicao-personalizada',
};

function Home() {
  const plans = [
    { title: 'Melhorar Imagem', price: 'R$ 20.00', features: ['Aumentar qualidade da imagem', 'Edição simples de imagem'], type: EditTypes.MELHORAR_IMAGEM },
    { title: 'Restaurar Imagem', price: 'R$ 25.00', features: ['Restaurar imagens antigas', 'Restaurar retratos'], type: EditTypes.RESTAURAR_IMAGEM },
    { title: 'Retirar/Adicionar pessoas ou objetos', price: 'R$ 35.00', features: ['Remover ou adicionar objetos', 'Remover ou adicionar pessoas'], type: EditTypes.RETIRAR_ADICIONAR_OBJETOS },
    { title: 'Editar membros do corpo', price: 'R$ 50.00', features: ['Editar membros do corpo'], type: EditTypes.EDITAR_MEMBROS },
    { title: 'Edição personalizada', price: 'A combinar', type: EditTypes.EDICAO_PERSONALIZADA },
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {plans.map((plan, index) => (
          <div key={index} className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-6 flex flex-col justify-between">
            <div>
              <h2 className="text-xl font-bold mb-2">{plan.title}</h2>
              <p className="text-gray-700 text-base mb-4">{plan.price}</p>
              <ul className="list-disc list-inside mb-4">
                {plan?.features?.map((feature, idx) => (
                  <li key={idx} className="text-gray-700 text-base">{feature}</li>
                ))}
              </ul>
            </div>
            <button
              className="bg-black text-white px-4 py-2 rounded mt-auto"
              onClick={() => handleEdit(plan.type)}
            >
              Quero editar agora
            </button>
          </div>
        ))}
      </div>
    </div>
  );

  function handleEdit(type) {
    // Navegar para a rota correspondente
    window.location.href = `/editar/${type}`;
  }
}

export default Home;
